.root{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container{
    display:flex;
    flex-direction: column;
    border: solid 0.1px grey;
    height: 30rem;
    overflow-y: scroll;
    width: 20rem;
    margin: 0 5rem 0 5rem;
    padding: 0.1rem;
    border-radius: 0.5rem;
}

.container2{
    margin: 3rem;
    display:flex;
    flex-direction: column;
    text-align: center;
    padding: 0.1rem;
    border-radius: 0.5rem;
}

::-webkit-scrollbar {
    width: 0;
  }

.buttonSelected{
    margin:0.1rem;
    padding: 1rem;
}